@import url(https://fonts.googleapis.com/css2?family=EB+Garamond&family=Roboto:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=EB+Garamond&family=Roboto:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=EB+Garamond&family=Roboto:wght@500&display=swap);
.qrmain {
  padding: 15px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}
.qrpints {
  margin-right: 15px;
  font-size: 18px;
  width: 100%;
  height: 100%;
  color: black;
}
.qrpints li {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-size: 18px;
  font-weight: 500;
}
.qrimage {
  margin-left: 5px;
  width: 100%;
  height: 100%;
  padding: 8px;
  border-radius: 8px;
  background: #fff;
}
.info {
  margin-right: 15px;
}
.snackbarError .MuiPaper-root {
  background: rgb(211, 47, 47) !important;
}
.snackbarInfo .MuiPaper-root {
  background: rgb(38, 193, 204) !important;
}
.snackbarSuccess .MuiPaper-root {
  background: rgb(46, 125, 50) !important;
}
.MuiAlert-icon,
.MuiAlert-message,
.MuiAlert-action {
  color: #fff !important;
}

.fold-corner {
  background: #f3f3f4;
  box-shadow: 0px 15px 10px -15px #111 !important;
  -webkit-mask: linear-gradient(45deg, #000 94%, #0000 0) bottom/100% 100%;
  -webkit-mask-repeat: no-repeat;
  transition: all 0.4s;
}
.fold-corner:focus-visible {
  border: none;
}
.fold-corner:not(.templateModalBox):hover {
  -webkit-mask: linear-gradient(45deg, #000 91%, #0000 0) bottom/100% 100%;
}
.fold-corner::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  border-style: solid;
  border-width: 1rem;
  border-color: transparent transparent #a1aef7 #a1aef7;
  transition: all 0.4s;
  box-shadow: 0px 15px 10px -15px #111 !important;
}
.templateModalBox .fold-corner::after,
.templateModalBox .fold-corner:hover::after {
  border-width: 1.83rem;
  border-color: transparent transparent #536dfe #536dfe;
}
.templateModalBox .fold-corner:hover {
  -webkit-mask: linear-gradient(45deg, #000 94%, #0000 0) bottom/100% 100%;
}
.fold-corner:hover::after {
  border-width: 1.5rem;
  border-color: transparent transparent #536dfe #536dfe;
}
.fold-corner:hover {
  -webkit-mask: linear-gradient(45deg, #000 92%, #0000 0) bottom/100% 100%;
}
.templateContent {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  white-space: pre-line;
  line-height: 15px;
  font-family: "Roboto", sans-serif;
}
.css-1qvr50w-MuiTypography-root {
  font-size: 1rem !important;
  font-weight: 700 !important;
  color: #536dfe;
}
.templateModalBox:focus-visible {
  outline: none !important;
}
.templateModalBox .fold-corner::after {
  border-color: #f3f3f4 #f3f3f4 #a1aef7 #a1aef7;
}
.templateModalBox::-webkit-scrollbar {
  display: none;
}

.MuiPaper-root {
  border-radius: 8px !important;
}

.ribbon-2 {
  --f: 8px; /* control the folded part*/
  --r: 7px; /* control the ribbon shape */
  --t: 8px; /* the top offset */
  position: absolute;
  inset: var(--t) calc(-1 * var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  -webkit-clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
          clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
  background: #536dfe;
  box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
}

.cardContainer {
  position: relative;
  padding: 0;
  -webkit-filter: drop-shadow(-1px 3px 2px #536dfe);
}

.cardMainHeading {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 0 5px;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.cardContent {
  font-size: 13px;
  font-weight: 400;
  margin: 0;
}

#simple-popover {
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 27%;
  left: 70%;
}
.errorMsg {
  margin: 0;
}
.errorMsgCommon {
  display: flex;
  justify-content: center;
  margin: 0 0 5px;
}
.errorMsg small, .errorMsgCommon small {
  font-size: 12px;
  color: #d30000;
}
@media screen and (max-width: 500px) {
  #simple-popover {
    top: 27%;
    left: 10%;
  }
}
.search {
  position: relative;
  border-radius: 25px;
  /* max-width: 300px; */
  margin-right: 10px;
  padding: 2px 35px 2px 15px;
  border: 1px solid #6e6e6ecc;
  display: flex;
  align-items: center;
}
.searchIcon {
  background-color: #6f98d1;
  width: 28px;
  color: #6f98d1;
  right: 5px;
  /* top: 0; */
  background: none !important;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchInput {
  width: 100%;
  font-size: 14px;
}
.searchInput:focus-visible {
  outline: none;
}

.fold {
  background: #f3f3f4;
  box-shadow: 0px 15px 10px -15px #111 !important;
  -webkit-clip-path: none;
          clip-path: none;
  -webkit-mask: radial-gradient(7px at bottom, #0000 59%, white) 70% / 22.3px
    100%;
  -webkit-mask-composite: destination-out;
}
.shadow {
  -webkit-filter: drop-shadow(-1px 3px 2px #536dfe);
}

.cardContent {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  white-space: pre-line;
  line-height: 1.2;
  font-family: "Roboto", sans-serif;
}
.css-1qvr50w-MuiTypography-root {
  font-size: 1rem !important;
  font-weight: 700 !important;
  color: #536dfe;
}
.css-1ujykiq-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background-color: lightgray !important;
}
.invoiceFileLink .MuiInputBase-input {
  padding-right: 37px;
}
.css-1x4vipr-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
  color: white !important;
  background-color: #536DFE !important;
}

.fold-corner {
  background: #f3f3f4;
  box-shadow: 0px 15px 10px -15px #111 !important;
  -webkit-mask: linear-gradient(45deg, #000 94%, #0000 0) bottom/100% 100%;
  -webkit-mask-repeat: no-repeat;
  transition: all 0.4s;
}
.fold-corner:focus-visible {
  border: none;
}
.fold-corner:not(.templateModalBox):hover {
  -webkit-mask: linear-gradient(45deg, #000 91%, #0000 0) bottom/100% 100%;
}
.fold-corner::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  border-style: solid;
  border-width: 1rem;
  border-color: transparent transparent #a1aef7 #a1aef7;
  transition: all 0.4s;
  box-shadow: 0px 15px 10px -15px #111 !important;
}
.templateModalBox .fold-corner::after,
.templateModalBox .fold-corner:hover::after {
  border-width: 1.83rem;
  border-color: transparent transparent #536dfe #536dfe;
}
.templateModalBox .fold-corner:hover {
  -webkit-mask: linear-gradient(45deg, #000 94%, #0000 0) bottom/100% 100%;
}
.fold-corner:hover::after {
  border-width: 1.5rem;
  border-color: transparent transparent #536dfe #536dfe;
}
.fold-corner:hover {
  -webkit-mask: linear-gradient(45deg, #000 92%, #0000 0) bottom/100% 100%;
}
.templateContent {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  white-space: pre-line;
  line-height: 15px;
  font-family: "Roboto", sans-serif;
}
.css-1qvr50w-MuiTypography-root {
  font-size: 1rem !important;
  font-weight: 700 !important;
  color: #536dfe;
}
.templateModalBox:focus-visible {
  outline: none !important;
}
.templateModalBox .fold-corner::after {
  border-color: #f3f3f4 #f3f3f4 #a1aef7 #a1aef7;
}
.templateModalBox::-webkit-scrollbar {
  display: none;
}

body {
  overflow: hidden;
}

.tabHeading h3 {
  font-weight: 500;
  color: #536dfe;
  font-size: 14px;
}

.tabIcon {
  width: 18px !important;
  height: 18px !important;
  color: #536dfe !important;
}

.infoIcon {
  width: 20px !important;
  height: 20px !important;
  color: #536DFE !important;
}

.toolTipTable {
  /* max-height: 45vh; */
  width: 100%;
  /* overflow-y: auto; */
  overflow-x: hidden;
}
/* .MuiPopover-paper {
  overflow: hidden !important;
} */

.inbox {
  padding: 0;
  margin: 0;
  min-width: 330px;
}
.inbox::-webkit-scrollbar {
  display: none;
}
.inboxHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  background: #c2e7ff;
}
.inboxHeading {
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}
.inboxClose {
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
}
.inboxTo,
.inboxMessageType,
.inboxTemplate {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0px 12px 10px;
}
.inboxTo p,
.inboxTemplate p,
.inboxMessageType p {
  font-weight: 400;
  margin: 0 10px 0 0;
}
.inboxText {
  padding: 0px 12px;
  max-height: 33vh;
  overflow-y: auto;
}
.inboxText::-webkit-scrollbar {
  display: none;
}
.inboxTextField {
  width: 100%;
  border: none !important;
}
.inboxTextField::-webkit-scrollbar {
  display: none;
}
.inboxBottom,
.inboxAttachment,
.inboxBottomRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inboxBottom {
  padding: 5px 12px 10px;
}
.inboxAttachment {
  padding: 5px 12px 0px;
}
.inboxAttachment small {
  font-size: 12px;
  line-height: 13px;
  margin: 0;
  font-weight: 400;
}
.sendBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0b57d0;
  border-radius: 20px;
}
.sendBtn button {
  border: none;
  background: none;
  color: #fff;
  padding: 10px 10px 10px 16px;
  cursor: pointer;
}
.buttonDivider {
  border-right: 1px solid #403f3f;
  height: 30px;
}
.scheduleHeading {
  padding: 10px 15px;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.scheduleCard {
  padding: 15px;
  width: 300px;
}
.scheduleCardHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.scheduleCardHead h3 {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
}
.scheduleCard p {
  margin: 0;
  font-size: 13px;
  font-weight: 300;
}
.scheduleFooter {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 10px;
}
.scheduleFooter Button {
  border-radius: 10px !important;
}
.clearFrequency {
  position: absolute;
  top: -7px;
  right: -7px;
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
  background: #fff !important;
}

.search {
  position: relative;
  border-radius: 25px;
  /* max-width: 300px; */
  /* width: 100%; */
  margin-right: 10px;
  padding: 2px 35px 2px 15px;
  border: 1px solid #6e6e6ecc;
  display: flex;
  align-items: center;
}
.searchIcon {
  background-color: #6f98d1;
  width: 28px;
  color: #6f98d1;
  right: 5px;
  /* top: 0; */
  background: none !important;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchInput {
  width: 100%;
  font-size: 14px;
}
.searchInput:focus-visible {
  outline: none;
}

/* body {
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  font-family: Arial, sans-serif;
} */

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader-circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-animation: pulse 1.5s ease-in-out infinite;
          animation: pulse 1.5s ease-in-out infinite;
}

.loader-circle:before {
  content: "";
  display: block;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 8px solid #536dfe;
  border-color: #536dfe transparent #536dfe transparent;
  -webkit-animation: loader 1.2s linear infinite;
          animation: loader 1.2s linear infinite;
}

.loader-text {
  color: #536dfe;
  font-size: 24px;
  font-weight: bold;
  margin-top: 16px;
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0.5;
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0.5;
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0.5;
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0.5;
  }
}
pre {outline: 1px solid #ccc; padding: 5px; margin: 5px; }
.string { color: green; }
.number { color: darkorange; }
.boolean { color: blue; }
.null { color: magenta; }
.key { color: red; }
