@import url("https://fonts.googleapis.com/css2?family=EB+Garamond&family=Roboto:wght@500&display=swap");
.fold {
  background: #f3f3f4;
  box-shadow: 0px 15px 10px -15px #111 !important;
  clip-path: none;
  -webkit-mask: radial-gradient(7px at bottom, #0000 59%, white) 70% / 22.3px
    100%;
  -webkit-mask-composite: destination-out;
}
.shadow {
  -webkit-filter: drop-shadow(-1px 3px 2px #536dfe);
}

.cardContent {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  white-space: pre-line;
  line-height: 1.2;
  font-family: "Roboto", sans-serif;
}
.css-1qvr50w-MuiTypography-root {
  font-size: 1rem !important;
  font-weight: 700 !important;
  color: #536dfe;
}
.css-1ujykiq-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background-color: lightgray !important;
}
.invoiceFileLink .MuiInputBase-input {
  padding-right: 37px;
}
.css-1x4vipr-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
  color: white !important;
  background-color: #536DFE !important;
}
