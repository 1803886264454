body {
  overflow: hidden;
}

.tabHeading h3 {
  font-weight: 500;
  color: #536dfe;
  font-size: 14px;
}

.tabIcon {
  width: 18px !important;
  height: 18px !important;
  color: #536dfe !important;
}

.infoIcon {
  width: 20px !important;
  height: 20px !important;
  color: #536DFE !important;
}

.toolTipTable {
  /* max-height: 45vh; */
  width: 100%;
  /* overflow-y: auto; */
  overflow-x: hidden;
}
/* .MuiPopover-paper {
  overflow: hidden !important;
} */

.inbox {
  padding: 0;
  margin: 0;
  min-width: 330px;
}
.inbox::-webkit-scrollbar {
  display: none;
}
.inboxHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  background: #c2e7ff;
}
.inboxHeading {
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}
.inboxClose {
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
}
.inboxTo,
.inboxMessageType,
.inboxTemplate {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0px 12px 10px;
}
.inboxTo p,
.inboxTemplate p,
.inboxMessageType p {
  font-weight: 400;
  margin: 0 10px 0 0;
}
.inboxText {
  padding: 0px 12px;
  max-height: 33vh;
  overflow-y: auto;
}
.inboxText::-webkit-scrollbar {
  display: none;
}
.inboxTextField {
  width: 100%;
  border: none !important;
}
.inboxTextField::-webkit-scrollbar {
  display: none;
}
.inboxBottom,
.inboxAttachment,
.inboxBottomRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inboxBottom {
  padding: 5px 12px 10px;
}
.inboxAttachment {
  padding: 5px 12px 0px;
}
.inboxAttachment small {
  font-size: 12px;
  line-height: 13px;
  margin: 0;
  font-weight: 400;
}
.sendBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0b57d0;
  border-radius: 20px;
}
.sendBtn button {
  border: none;
  background: none;
  color: #fff;
  padding: 10px 10px 10px 16px;
  cursor: pointer;
}
.buttonDivider {
  border-right: 1px solid #403f3f;
  height: 30px;
}
.scheduleHeading {
  padding: 10px 15px;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.scheduleCard {
  padding: 15px;
  width: 300px;
}
.scheduleCardHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.scheduleCardHead h3 {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
}
.scheduleCard p {
  margin: 0;
  font-size: 13px;
  font-weight: 300;
}
.scheduleFooter {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 10px;
}
.scheduleFooter Button {
  border-radius: 10px !important;
}
.clearFrequency {
  position: absolute;
  top: -7px;
  right: -7px;
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
  background: #fff !important;
}

.search {
  position: relative;
  border-radius: 25px;
  /* max-width: 300px; */
  /* width: 100%; */
  margin-right: 10px;
  padding: 2px 35px 2px 15px;
  border: 1px solid #6e6e6ecc;
  display: flex;
  align-items: center;
}
.searchIcon {
  background-color: #6f98d1;
  width: 28px;
  color: #6f98d1;
  right: 5px;
  /* top: 0; */
  background: none !important;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchInput {
  width: 100%;
  font-size: 14px;
}
.searchInput:focus-visible {
  outline: none;
}

/* body {
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  font-family: Arial, sans-serif;
} */

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader-circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: pulse 1.5s ease-in-out infinite;
}

.loader-circle:before {
  content: "";
  display: block;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 8px solid #536dfe;
  border-color: #536dfe transparent #536dfe transparent;
  animation: loader 1.2s linear infinite;
}

.loader-text {
  color: #536dfe;
  font-size: 24px;
  font-weight: bold;
  margin-top: 16px;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.8);
    opacity: 0.5;
  }
}
pre {outline: 1px solid #ccc; padding: 5px; margin: 5px; }
.string { color: green; }
.number { color: darkorange; }
.boolean { color: blue; }
.null { color: magenta; }
.key { color: red; }