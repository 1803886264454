.ribbon-2 {
  --f: 8px; /* control the folded part*/
  --r: 7px; /* control the ribbon shape */
  --t: 8px; /* the top offset */
  position: absolute;
  inset: var(--t) calc(-1 * var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
  background: #536dfe;
  box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
}

.cardContainer {
  position: relative;
  padding: 0;
  -webkit-filter: drop-shadow(-1px 3px 2px #536dfe);
}

.cardMainHeading {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 0 5px;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.cardContent {
  font-size: 13px;
  font-weight: 400;
  margin: 0;
}
