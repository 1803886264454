.qrmain {
  padding: 15px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}
.qrpints {
  margin-right: 15px;
  font-size: 18px;
  width: 100%;
  height: 100%;
  color: black;
}
.qrpints li {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-size: 18px;
  font-weight: 500;
}
.qrimage {
  margin-left: 5px;
  width: 100%;
  height: 100%;
  padding: 8px;
  border-radius: 8px;
  background: #fff;
}
.info {
  margin-right: 15px;
}
.snackbarError .MuiPaper-root {
  background: rgb(211, 47, 47) !important;
}
.snackbarInfo .MuiPaper-root {
  background: rgb(38, 193, 204) !important;
}
.snackbarSuccess .MuiPaper-root {
  background: rgb(46, 125, 50) !important;
}
.MuiAlert-icon,
.MuiAlert-message,
.MuiAlert-action {
  color: #fff !important;
}
