#simple-popover {
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 27%;
  left: 70%;
}
.errorMsg {
  margin: 0;
}
.errorMsgCommon {
  display: flex;
  justify-content: center;
  margin: 0 0 5px;
}
.errorMsg small, .errorMsgCommon small {
  font-size: 12px;
  color: #d30000;
}
@media screen and (max-width: 500px) {
  #simple-popover {
    top: 27%;
    left: 10%;
  }
}
.search {
  position: relative;
  border-radius: 25px;
  /* max-width: 300px; */
  margin-right: 10px;
  padding: 2px 35px 2px 15px;
  border: 1px solid #6e6e6ecc;
  display: flex;
  align-items: center;
}
.searchIcon {
  background-color: #6f98d1;
  width: 28px;
  color: #6f98d1;
  right: 5px;
  /* top: 0; */
  background: none !important;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchInput {
  width: 100%;
  font-size: 14px;
}
.searchInput:focus-visible {
  outline: none;
}
