@import url("https://fonts.googleapis.com/css2?family=EB+Garamond&family=Roboto:wght@500&display=swap");
.fold-corner {
  background: #f3f3f4;
  box-shadow: 0px 15px 10px -15px #111 !important;
  -webkit-mask: linear-gradient(45deg, #000 94%, #0000 0) bottom/100% 100%;
  -webkit-mask-repeat: no-repeat;
  transition: all 0.4s;
}
.fold-corner:focus-visible {
  border: none;
}
.fold-corner:not(.templateModalBox):hover {
  -webkit-mask: linear-gradient(45deg, #000 91%, #0000 0) bottom/100% 100%;
}
.fold-corner::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  border-style: solid;
  border-width: 1rem;
  border-color: transparent transparent #a1aef7 #a1aef7;
  transition: all 0.4s;
  box-shadow: 0px 15px 10px -15px #111 !important;
}
.templateModalBox .fold-corner::after,
.templateModalBox .fold-corner:hover::after {
  border-width: 1.83rem;
  border-color: transparent transparent #536dfe #536dfe;
}
.templateModalBox .fold-corner:hover {
  -webkit-mask: linear-gradient(45deg, #000 94%, #0000 0) bottom/100% 100%;
}
.fold-corner:hover::after {
  border-width: 1.5rem;
  border-color: transparent transparent #536dfe #536dfe;
}
.fold-corner:hover {
  -webkit-mask: linear-gradient(45deg, #000 92%, #0000 0) bottom/100% 100%;
}
.templateContent {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  white-space: pre-line;
  line-height: 15px;
  font-family: "Roboto", sans-serif;
}
.css-1qvr50w-MuiTypography-root {
  font-size: 1rem !important;
  font-weight: 700 !important;
  color: #536dfe;
}
.templateModalBox:focus-visible {
  outline: none !important;
}
.templateModalBox .fold-corner::after {
  border-color: #f3f3f4 #f3f3f4 #a1aef7 #a1aef7;
}
.templateModalBox::-webkit-scrollbar {
  display: none;
}
